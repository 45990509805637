<script>
  import store from "../../store/index.js";
  export default {
    data() {
      return {
        playVideoIcon: null,
        indicative: "Sin archivos seleccionados",
        onChangeVideo: false,
        thumbnail: null,
        fileURL: "",
        statusModal: false,
        nameVideo: "MP4, HIC, MOV",
        videoInput: null,
        isIOS: this.detectIOS(),
        isWindowsOrAndroid: this.detectWindowsOrAndroid(),
        errorMessage: "",
      };
    },
    computed: {
      textField() {
        const { unSlect = "", Example = "", indicative = "", indicative_Sub = "", indicative_second = "", indicative_Priority = "", indicative_third = "" } = this.fieldIndicators;
        return { unSlect, Example, indicative, indicative_Sub, indicative_second, indicative_Priority, indicative_third };
      },
      fieldIndicators() {
        const { videoField } = store.getters.newLocale;
        return videoField || {};
      },
    },
    methods: {
      detectIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      },
      detectWindowsOrAndroid() {
        return /Windows|Android/.test(navigator.userAgent);
      },
      isValidVideo(file) {
        const validVideoTypes = ["video/mp4", "video/quicktime", "video/x-matroska", "video/webm"];
        const validExtensions = ["mp4", "mov", "mkv", "webm"];
        const fileType = file.type;
        const fileExtension = file.name.split(".").pop().toLowerCase();
        return validVideoTypes.includes(fileType) || validExtensions.includes(fileExtension);
      },
      uploadedVideo(event) {
        this.$refs.label.style.background = "#f9e7e7";
        const file = event.target.files[0];
        if (this.sizeValidation(file)) {
          return;
        }
        if (file && !this.isValidVideo(file)) {
          this.$refs.videoInput.value = null;
          this.deleteVideo();
          return;
        }
        this.$refs.label.style.border = "";
        this.errorMessage = "";
        this.nameVideo = file.name;
        this.onChangeVideo = true;
        this.createPreview(file);
        this.$emit("fileVideoModel", file);
      },
      sizeValidation(file) {
        const maxFileSize = 100 * 1024 * 1024;
        if (file.size > maxFileSize) {
          this.$refs.label.style.border = "solid 2px #bd0909";
          this.errorMessage = this.fieldIndicators.largeVideo;
          this.deleteVideo(this.errorMessage);
          return true;
        }
      },
      async createPreview(file) {
        this.fileURL = URL.createObjectURL(file);
        if (this.isIOS || this.isWindowsOrAndroid) {
          this.thumbnail = this.fileURL;
          if (this.isWindowsOrAndroid) {
            const video = document.createElement("video");
            video.src = this.fileURL;
            video.currentTime = 0.2;
            video.addEventListener(
              "loadeddata",
              () => {
                if (video.videoWidth && video.videoHeight) {
                  const canvas = document.createElement("canvas");
                  canvas.width = video.videoWidth;
                  canvas.height = video.videoHeight;
                  const context = canvas.getContext("2d");
                  setTimeout(() => {
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    this.thumbnail = canvas.toDataURL("image/png");
                  }, 100);
                }
              },
              { once: true }
            );
          }
        } else {
          this.thumbnail = this.fileURL;
        }
        this.$emit("sendUrl", this.fileURL);
      },
      onThumbnailClick(event) {
        if (this.onChangeVideo) {
          event.preventDefault();
          this.$emit("videoModel", this.fileURL);
        }
      },
      deleteVideo() {
        this.onChangeVideo = false;
        this.nameVideo = "MOV, HIC, MP4";
        this.$refs.videoInput.value = null;
        this.$refs.label.style.background = "";
        this.fileURL = "";
        this.thumbnail = null;
        this.$emit("fileVideoModel", "");
        this.$emit("sendUrl");
        if (!this.errorMessage) {
          this.errorMessage = "";
        }
      },
      changeStatusModal() {
        this.statusModal = !this.statusModal;
      },
    },
  };
</script>

<template>
  <div class="uploadvideo">
    <div class="uploadvideo__wrapper">
      <div class="uploadvideo__container">
        <label ref="label" @click="onThumbnailClick" class="uploadvideo__label" for="videoModel">
          <div v-if="onChangeVideo" class="uploadvideo__preview">
            <img class="uploadvideo__img" :src="thumbnail" alt="Video Thumbnail" v-if="thumbnail" />
          </div>
          <iconic v-else class="uploadvideo__cameraicon" name="camera" />
          <div class="uploadvideo__wrap">
            <p class="uploadvideo__type">{{ nameVideo }}</p>
            <input ref="videoInput" class="uploadvideo__input" name="videoModel" id="videoModel" @change="uploadedVideo" type="file" accept=".mov,.hic,.mp4" />
            <p class="uploadvideo__txt" v-if="!onChangeVideo">{{ textField.unSlect }}</p>
          </div>
          <div v-if="onChangeVideo" class="uploadvideo__iconTrash" @click.prevent="deleteVideo">
            <iconic class="uploadvideo__trash" name="trash" />
          </div>
        </label>
      </div>
      <div class="uploadvideo__example" @click="$emit('videoModel')">
        <div class="uploadvideo__circle">
          <iconic class="uploadvideo__iconplay" name="playVideo" />
        </div>
        <p class="uploadvideo__textexample">{{ textField.Example }}</p>
      </div>
    </div>
    <p v-if="errorMessage" class="uploadvideo__tooltip">{{ errorMessage }}</p>
    <p class="uploadvideo__fieldIndication">
      {{ textField.indicative }} <sub class="uploadvideo__sub">{{ textField.indicative_Sub }} </sub> {{ textField.indicative_second }}
      <sub class="uploadvideo__priority">{{ textField.indicative_Priority }}</sub> {{ textField.indicative_third }}
    </p>
  </div>
</template>

<style lang="scss">
  .uploadvideo {
    width: 100%;
    margin-bottom: $space-16;
    &__wrapper {
      @include Flex(column, start, start);
      width: 100%;
      margin-bottom: 14px;
      transition: 0.5s;
      gap: $space-16;
    }
    &__label {
      @include Flex(row, center, center);
      gap: $space-16;
      width: 100%;
      height: 58px;
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    }
    &__container {
      width: 100%;
      height: 100%;
      background: #ebebeb;
      border-radius: 10px;
      transition: 0.3s;
      &:hover {
        background-color: rgba(79, 79, 79, 0.25);
      }
    }
    &__example {
      @include Flex(row, center, center);
      width: 100%;
      height: 58px;
      border-radius: 10px;
      background: $primary-color;
      cursor: pointer;
      padding: $space-0 $space-6;
      gap: $space-16;
    }
    &__input {
      display: none;
      width: fit-content;
    }
    &__wrap {
      width: fit-content;
    }
    &__cameraicon {
      color: #000;
      font-size: 30px;
    }
    &__type {
      color: #171717;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      width: fit-content;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__txt {
      font-size: 10px;
      color: #4a4a4a;
      font-weight: 400;
    }
    &__circle {
      @include Flex(row, center, center);
      min-width: 30px;
      min-height: 30px;
      border-radius: 100%;
      border: solid 1px #fff;
    }
    &__iconplay {
      color: #fff;
      font-size: 12px;
      padding: $space-2 $space-0 $space-4 $space-2;
      line-height: 0px !important;
    }
    &__textexample {
      font-size: 12px;
      color: #fff;
      font-weight: 600;
      width: fit-content;
      text-align: center;
    }
    &__img {
      width: 100%;
      height: 100%;
    }
    &__preview {
      @include Flex(row, center, center);
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: $radius-6;
    }
    &__trash {
      font-size: 26px;
      color: $primary-color;
    }
    &__iconTrash {
      @include Flex(row, center, center);
      width: 20px;
      height: 100%;
    }
    &__fieldIndication {
      font-size: 16px;
      color: #5b5957;
      text-align: justify;
    }
    &__sub,
    &__priority {
      font-size: 16px;
      position: relative;
      top: -4px;
      font-weight: 600;
    }
    &__sub {
      color: $primary-color;
    }
    &__priority {
      color: #3a3a39;
    }
    &__tooltip {
      position: relative;
      font-size: 75%;
      margin-top: $space-2;
      color: $primary_color;
      font-weight: bold;
      margin-bottom: $space-16;
      text-align: justify;
    }
    @media screen and (min-width: 425px) {
      &__example {
        gap: 10px;
      }
    }
    @media screen and (min-width: 550px) {
      &__wrapper {
        @include Flex(row, start);
        gap: $space-16;
      }
      &__label {
        @include Flex(row, center, center);
        gap: 14px;
      }
      &__cameraicon {
        font-size: 35px;
      }
      &__type {
        font-size: 16px;
        text-align: start;
        max-width: 140px;
      }
      &__txt {
        font-size: 12px;
      }
      &__container {
        width: 103%;
      }
    }
    @media screen and (min-width: 768px) {
      &__type,
      &__txt {
        font-size: 11px;
        width: fit-content;
      }
      &__cameraicon {
        font-size: 35px;
      }
      &__textexample {
        font-size: 15px;
      }
      &__label {
        padding: $space-0 10px;
      }
      &__type {
        max-width: 84px;
      }
    }
    @media (min-width: 980px) and (max-width: 1024px) {
      &__wrapper {
        flex-wrap: wrap;
      }
    }
    @media screen and (min-width: 1540px) {
      &__textexample,
      &__type {
        font-size: 13px;
      }
      &__type {
        max-width: 100px;
      }
      &__iconplay {
        padding: $space-0 $space-0 $space-2 $space-2;
      }
    }
  }
</style>
